<template>
  <div class="container">
    <div class="header"><router-link to="/"><img src="../assets/images/back.png" alt=""></router-link> 消息</div>
    <div class="blian22"></div>
    <div class="message-list">
      <ul>
        <li v-for="(item,index) in messageArr" :key="index" @click="goMsgDetail(item)">
          <h3 class="ellipsis_one">{{item.title}}</h3>
          <p>{{item.msgTime}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
	import { sysMsgList } from "@/assets/js/api";
export default {
  name: "message",
  data() {
    return {
      messageArr:[]
    };
  },
  methods: {
  	//获取消息列表
			getsysMsgList() {
				sysMsgList({}).then(res => {
					if(res.code == 0) {
						this.messageArr = res.msg
					}
				});
			},
			//去消息详情
			goMsgDetail(item){
				this.$router.push({
						path: '/messageDetail',
						query: {
							info: item.msg
						}
				})
			}
  },
  mounted() {
  	this.getsysMsgList()
  }
};
</script>


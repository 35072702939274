<template>
	<div class="msgDetail">
		<div class="header"><a @click="$router.back(-1)"><img src="../assets/images/back.png" alt=""></a> 消息</div>
		 <div v-html="msgInfo" style="margin-top: 2.2rem;" class="ueInfo ql-editor"></div>
	</div>
</template>

<script>
	export default {
  name: "messageDetail",
  data() {
    return {
      msgInfo:''
    };
  },
  methods: {
  	
  },
  mounted() {
  	this.msgInfo = this.$route.query.info;
  }
};
</script>

<style>
	.ueInfo img{ max-width: 100%;height: auto; }
</style>